<template>
    <div class="recent-projects-container" v-if="recentProjects.length>0">
        <div class="recent-projects-header">
              <h3>
                <template v-if="type=='shared'">Shared with you</template>
                <template v-else-if="type=='team'">Recent team projects</template>
                <template v-else>Recent projects</template>
             </h3>
        </div>
        <div class="recent-projects-list mt-5 mb-5">
            <template v-if="fetchingRecentProjects">
                <v-skeleton-loader height="82" width="318" class="recent-project-loader mr-10" type="image"/>
                <v-skeleton-loader height="82" width="318" class="recent-project-loader mr-10" type="image"/>
                <v-skeleton-loader height="82" width="318" class="recent-project-loader mr-10" type="image"/>
                <v-skeleton-loader height="82" width="318" class="recent-project-loader mr-10" type="image"/>
            </template>
            <template v-else>
                <div :key="project.id" v-for="(project,ind) in recentProjects" 
                    :style="{'margin-right':(ind===0 || ind%3!==0)?'15px':'0px'}"
                >
                    <router-link
                      :to="{
                       path: type == 'recent' ? `/project/${project.project_id}/sessions` 
                                                : `/project/${project.id}/sessions`,
                      }"
                      class="recent-project"
                      @click.native="
                        trackProjectCardClick()
                      "
                    >
                    <div class="project-emoji-container">
                        <template v-if="project.default">
                            <img  src="../../../public/loop-logo-small.svg" />
                        </template>
                        <template v-else-if="project.emoji">
                            <span>{{project.emoji}}</span>
                        </template>
                        <template v-else>
                            <span>🎉</span>
                        </template>
                    </div>
                    <div class="project-details-container">
                        <div>
                            <h4>{{project.name | trimProjectName }}</h4>
                        </div>
                        <div class="project-details-metadata">
                            <div class="mr-2">
                                <i class="ri-folders-line"></i>
                                {{project.sessionCount}}
                            </div>
                            <div>
                                <i class="ri-time-line"></i>
                                <template v-if="type == 'recent'">
                                    Updated {{ formatToRelativeTime(new Date(project.updatedOn))}}
                                </template>
                                <template v-else>
                                    Updated {{ formatToRelativeTime(project.updatedOn.toDate())}}
                                </template>
                            </div>
                            <div class="shared-details" v-if="type=='recent' && project.owner">
                                <img src="../../../public/shared-icon.svg" class="mr-2" />
                                <div v-if="project.createdBy">
                                    {{  project.createdBy | formatName }}
                                </div>
                            </div>
                        </div>
                    </div>
                    </router-link>

                </div>
            </template>

        </div>
    </div>
</template>

<script>
import ProjectsAPI from '@/api/projects/projects';
import formatDistance from "date-fns/formatDistance";
import { auth } from "@/firebase"
import { mapState } from "vuex";

export default {
    props:{
        type:{
            default:"recent"
        }
    },
    data(){
        return{
            recentProjects:[],
            fetchingRecentProjects:true
        }
    },
    computed: {
    ...mapState(["userWorkspace"]),
    },
    mounted(){
        // if(this.type=='shared') this.getSharedProjects()
        if(this.type=='team') this.getTeamProjects()
        else this.getRecentProjects();
    },
    filters:{
        formatName(val){
            console.log('VAL: ',val)
            const separated = val.split(' ');
            if(separated.length==1) val = separated[0]
            else val=separated[0][0]+separated[1][0]
            return val;
        },
        trimProjectName(val) {
            if(val.length>30) return `${val.substring(0, 27)}...`;
            return val
        },
  
    },
    methods:{
        async getTeamProjects(){
            const recentProjects=await ProjectsAPI.getRecentTeamProjects(this.userWorkspace.id);
            // console.log('Team projects:',{recentProjects})
            this.recentProjects=recentProjects;
            this.fetchingRecentProjects=false;
        },
        async getRecentProjects(){
            const recentProjects=await ProjectsAPI.getRecentProjects();
            // console.log({recentProjects})
            this.recentProjects=recentProjects.data;
            // console.log('recent projects: ',this.recentProjects)
            this.fetchingRecentProjects=false;
        },
        async getSharedProjects(){
            const sharedProjects=await ProjectsAPI.getSharedProjects()
            console.log({sharedProjects})
            this.recentProjects=sharedProjects.data;
            console.log('shared projects: ',this.recentProjects)
            this.fetchingRecentProjects=false;
        },
        formatToRelativeTime(date){
            const _date=new Date(date)
            return formatDistance(date, new Date(), { addSuffix: true });
        },
        trackProjectCardClick() {
            window.analytics.track("open individual project from home view", {
                email: auth.currentUser.email,
            });
        }
    }

}
</script>

<style scoped>

    .recent-project-loader{
        border-radius: 12px !important;
    }
    .recent-projects-header h3{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 21px;
        line-height: 25px;

        /* Neutrals/600 */

        color: #4C4C4C;
    }

    .recent-projects-list{
        display: flex;
        align-items: center;
        /* justify-content: space-between; */
        overflow-x:auto;
    }

    /* Hide the scrollbar for Chrome, Safari and Opera */
    .recent-projects-list::-webkit-scrollbar {
        display: none;
    }

    .recent-project{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        padding: 20px 24px;
        gap: 16px;

        width: 318px;
        height: 82px;

        background: #FFFFFF;
        /* Neutrals/200 */

        border: 1px solid #EDEDED;
        border-radius: 12px;
    }

    .project-emoji-container span{
        font-size: 18px;
    }
    .project-details-container{
        display: flex;
        flex-direction: column;
    }

    .project-details-container h4{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        /* identical to box height, or 21px */
        /* Neutrals/600 */
        color: #4C4C4C;
        text-transform: capitalize;
    }

    .project-details-metadata{
        display: flex;
        align-items: center;
    }
    .project-details-metadata div{
        display: flex;
        align-items: center;
        justify-content: space-around;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 160%;
        /* or 19px */


        /* Neutrals/400 */
        /* margin-right: 10px; */
        color: #999999;
    }

    .project-details-metadata div i{
        margin-right: 3px;
    }

    .shared-details{
        margin-left: 50px;
    }

</style>