//TODO: Put a routes vs route name /screen name mapping object here

const routes={
    mainlinks:[
        // {
        //     name:'home',
        //     alias:'dashboard',
        //     url:'/'
        // },
        {
            name:'projects',
            alias:'projects',
            url:'/projects',
        },        
        {
            name:'reels',
            alias:'reels',
            url:'/reels',
        },
        {
            name:'calendar',
            alias:'calendar',
            url:'/calendar',
        },
        {
            name:'settings',
            alias:'settings',
            url:'/settings',
        },
        {
            name:'search',
            alias:'search',
            url:'/search',
        }
    ],
    projectlinks:[
        {
            name:'Discussion Guide',
            alias:'script',
            url:'/script',
            skipDefault:true
        },
        {
            name:'Files',
            alias:'sessions',
            url:'/sessions',
            skipDefault:false

        },
        {
            name:'analysis',
            alias:'analysis',
            url:'/analysis?tab=question',
            skipDefault:true
        },
        // {
        //     name:'tags',
        //     alias:'tags',
        //     url:'/tags',
        //     skipDefault:false

        // },
    ],
    
}

export default routes;