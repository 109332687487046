export const ReelEvents = {
    'CLICKED_REEL_CARD' :{
        event:'clicked on the reel card from reels page',
        action:'Clicked on the reel card from reels page'
    },
    'CLICKED_COPY_LINK_REEL_CARD' :{
        event:'clicked copy link CTA on the reel card from reels page',
        action:'Clicked copy link CTA on the reel card from reels page'
    },
    'CLICKED_DELETE_REEL_CARD' :{
        event:'clicked delete reel CTA on the reel card from reels page',
        action:'Clicked delete reel CTA on the reel card from reels page'
    },
    'CLICKED_COPY_LINK_INDIVIDUAL_REEL_PAGE' :{
        event:'clicked copy link CTA from the individual reels page',
        action:'Clicked copy link CTA from the individual reels page',
    },
    'CLICKED_EDIT_INDIVIDUAL_REEL_PAGE' :{
        event:'clicked edit reel CTA from the individual reels page',
        action:'Clicked edit reel CTA from the individual reels page',
    },
    'CLICKED_CANCEL_EDIT_INDIVIDUAL_REEL_PAGE' :{
        event:'clicked cancel edited reel CTA from the individual reels page',
        action:'Clicked cancel edited reel CTA from the individual reels page',
    },
    'CLICKED_SAVE_EDIT_INDIVIDUAL_REEL_PAGE' :{
        event:'clicked save edited reel CTA from the individual reels page',
        action:'Clicked save edited reel CTA from the individual reels page',
    },
    'REMOVE_NOTE_FROM_INDIVIDUAL_REEL_PAGE' :{
        event:'removed note from reel from the individual reels page',
        action:'Removed note from reel from the individual reels page',
    },
    'CLICKED_ADD_NOTE_TO_REEL_CTA' :{
        event:'clicked add note to reel CTA from the session view page',
        action:'Clicked add note to reel CTA from the session view page',
    },
    'ASSIGN_OR_REMOVE_NOTE_TO_REEL' :{
        event:'assign or remove note to reel CTA from reels dropdown',
        action:'Assigned or remove note to reel CTA from reels dropdown',
    },
    'CREATE_REEL' :{
        event:'create a reel from reels dropdown',
        action:'Create a reel from reels dropdown',
    },
    'CLICKED_MANAGE_REELS' :{
        event:'clicked manage reels CTA from the session view page',
        action:'Clicked manage reels  CTA from the session view page',
    },
}
