<template>
  <v-dialog 
    v-model="showProjectModal" 
    @click:outside="$emit('toggle-modal')" 
    width="480" content-class="create-project-modal">
          <v-card class="create-project-modal__container">

            <v-card-title class="create-project-modal__header text-h5 d-flex-row" >
              
              <p class="create-project-modal-title" >
                {{ modalTitle }}
              </p>

              <span class="create-project-modal-closeBtn" 
                @click="$emit('toggle-modal')" >
                        &#x2715;
              </span>
            </v-card-title>

            <v-card-text class="create-project-modal__content flex-col mt-4">
              <div class="project-name-menu">
                  <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <button class="emoji-menu" v-bind="attrs" v-on="on">
                     <span style="font-size:20px">{{selectedEmoji}}</span>
                      <i class="ri-arrow-up-s-line"></i>
                    </button>
                  </template>
                  <div class="emojis-list-container">
                    <span @click="selectedEmoji=emoji" :key="emoji" v-for="emoji in emojis">{{emoji}}</span>
                  </div>
                </v-menu>
                <input v-model="projectName" required="true" class="main-input" placeholder="Enter project name" />

              </div>
 
              <div class="create-project-modal-actionContainer">

              <button
                class='rename-project-button'
                @click="handleProjectAction"
                :disabled="((projectName.length == 0) || (projectCreationLoading))"
              >
                <p class="create-project-modal-actionBtn mb-0 white--text" v-if="modalType == 'edit'">
                  <template v-if="projectLoading">
                    Updating your project...
                    <v-progress-circular indeterminate color="white" :size="16" :width="2" ></v-progress-circular>
                  </template>
                  <template v-else>
                    Update project   
                  </template>
                </p>
                
              </button>
              </div>
              
            </v-card-text>
          </v-card>
        </v-dialog>
        
</template>


<script>


export default{
    watch:{
        resetProjectLoading(newVal,oldVal){
            if (newVal == true){
                this.projectLoading = false;
            }
        },
        showProjectModal(newVal,oldVal){
          if (newVal){
            if (this.modalType == 'edit'){
                this.projectName = this.projectData.name
                this.selectedEmoji = this.projectData.emoji
                if (this.projectData.renamingProject){
                  this.projectLoading = true;
                }
              }
          }
        }
    },
    props:{
        showProjectModal:{
            type: Boolean,
            required: true,
        },
        modalType:{
          type: String,
          default: 'edit'
        },
        projectData:{},
        modalTitle:{},
        ctaActionName:{},
        resetProjectLoading:{
          default:false
        },
    },
    data(){
        return{
            projectName:'',
            emojis:[
              '⚽️','🎉','✨','🧠','👋','✌️','👀','🎾','💻','👾','📱','💊','💡','🗝','💰'
            ],
            selectedEmoji:'⚽️',
            projectLoading:false
        }
},
beforeDestroy(){
  this.projectName = null;
  this.selectedEmoji = null;
},

methods:{
    handleProjectAction(){
      if (this.projectLoading==true){
        return
      }
      if (this.modalType == 'edit'){
        this.projectLoading=true;
        if (this.projectName){
          this.$emit('update-project',{
          'projectData':this.projectData,
          'projectName':this.projectName,
          'selectedEmoji':this.selectedEmoji
        })
        }
      }
    },
 },
}

</script>

<style >

    .create-project-modal,.create-project-modal__container{
      border-radius: 16px !important;
    }
    .create-project-modal__container{
      padding: 24px;
    }

    .create-project-modal__header,.create-project-modal__content{
      padding:0px !important;
    }
    .create-project-modal-title{
        text-align:center; 
        font-weight:700; 
        font-size:21px; 
        color:#4C4C4C
    }
    .create-project-modal-closeBtn{
        font-size:16px; 
        margin-bottom:15px; 
        cursor:pointer
    }
    .create-project-modal-actionBtn{
        font-weight: 700 !important; 
        font-size:12px
    }

    .create-project-modal-actionContainer{
        display:flex;
        align-items:center;
        justify-content:space-between
    }

    .disable{
      opacity:0.7;
      cursor:not-allowed
    }

    button{
      outline: none;
    }

    .emoji-menu{
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 12px;
      gap: 4px;

      width: 68px;
      height: 50px;
      outline: none !important;
      /* Neutrals/200 */
      background: #EDEDED;
      border-radius: 48px 0px 0px 48px;
    }

    .emojis-list-container{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex-flow: wrap;
      justify-content: space-between;
      padding: 7px;

      width: 212px;
      height: 136px;

      background: #FFFFFF;
      /* Shadow- L1 */

      box-shadow: 0px 0px 8px rgba(102, 102, 102, 0.15);
      border-radius: 12px;
    }

    .emojis-list-container span{
      font-size: 20px;
      width: 36px;
      height: 36px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .emojis-list-container span:hover{
      background: #F8F8F8;
      border-radius: 8px;
    }

    .project-name-menu{
      display: flex;
      align-items: center;
      margin-bottom: 30px;
    }

    .rename-project-button{
      background: #5b7edb;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px 16px;
      height: 45px;
      border-radius: 44px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: -.01em;
      text-transform: capitalize;
    }
</style>