import { auth } from "@/firebase";
import axios from "axios";
import {getFunctionUrl} from '@/functions/functionsConfig'

// BACKEND_API
const REELS_MANAGER_ENDPOINT = getFunctionUrl('reels_manager');

// Fetch all metadata & values of the workspace
const getReelsForUser = async (user_id,workspace_id) => {
    try {
          let requestBody = {
            "user_id": user_id,
            "workspace_id": workspace_id,
          };          
          const reelsResponse = await axios.post(`${REELS_MANAGER_ENDPOINT}/get_reels_for_user`, requestBody)
        //   console.log('[debug reels]',reelsResponse)
          return { status: 'success', data : reelsResponse.data}
    } catch (error) {
          return{status:'error',error:error}
    }
  };

const getReel = async (reel_id,user_id,workspace_id) => {
try {
        let requestBody = {
        "reel_id": reel_id,
        "user_id": user_id,
        "workspace_id": workspace_id,
        };          
        const reelsResponse = await axios.post(`${REELS_MANAGER_ENDPOINT}/get_reel`, requestBody)
        // console.log('[debug reels]',reelsResponse)
        return { status: 'success', data : reelsResponse.data}
} catch (error) {
        return{status:'error',error:error}
}
};


const getReelAnon = async (reel_id) => {
    try {
            let requestBody = {
            "reel_id": reel_id,
            };          
            const reelsResponse = await axios.post(`${REELS_MANAGER_ENDPOINT}/get_reel_anon`, requestBody)
        //     console.log('[debug reels]',reelsResponse)
            return { status: 'success', data : reelsResponse.data}
    } catch (error) {
            return{status:'error',error:error}
    }
    };


const createReel = async (requestBody) => {
    try {
            const createReelResponse = await axios.post(`${REELS_MANAGER_ENDPOINT}/create_reel`, requestBody)
        //     console.log('[debug reels]',createReelResponse)
            return { status: 'success', data : createReelResponse.data}
    } catch (error) {
            return{status:'error',error:error}
    }
    };


const editReel = async (requestBody) => {
    try {
            const editReelResponse = await axios.post(`${REELS_MANAGER_ENDPOINT}/edit_reel`, requestBody)
        //     console.log('[debug reels]',editReelResponse)
            return { status: 'success', data : editReelResponse.data}
    } catch (error) {
            return{status:'error',error:error}
    }
    };

const deleteReel = async (requestBody) => {
    try {
            const deleteReelResponse = await axios.post(`${REELS_MANAGER_ENDPOINT}/delete_reel`, requestBody)
        //     console.log('[debug reels]',deleteReelResponse)
            return { status: 'success', data : deleteReelResponse.data}
    } catch (error) {
            return{status:'error',error:error}
    }
    };

const updateNoteReels = async (payload) => {
    try {        
            const reelsResponse = await axios.post(`${REELS_MANAGER_ENDPOINT}/update_note_and_reels`, payload)
        //     console.log('[debug reels]',reelsResponse)
            return { status: 'success', data : reelsResponse.data}
    } catch (error) {
            return{status:'error',error:error}
    }
    };


export default {
    getReelsForUser,
    getReel,
    getReelAnon,
    createReel,
    editReel,
    deleteReel,
    updateNoteReels
}