<template>
    <div v-if="isLoadingPage" class="card-list">
        <div>
        <v-skeleton-loader
        class="mr-auto ml-3 reels-loading-search"
        type="image"
        ></v-skeleton-loader>
        
        <v-skeleton-loader
        class="mr-auto ml-3 reels-loading-card"
        type="image"
        ></v-skeleton-loader>

        <v-skeleton-loader
        class="mr-auto ml-3 reels-loading-card"
        type="image"
        ></v-skeleton-loader>

        <v-skeleton-loader
        class="mr-auto ml-3 reels-loading-card"
        type="image"
        ></v-skeleton-loader>

        <v-skeleton-loader
        class="mr-auto ml-3 reels-loading-card"
        type="image"
        ></v-skeleton-loader>

        <v-skeleton-loader
        class="mr-auto ml-3 reels-loading-card"
        type="image"
        ></v-skeleton-loader>

        <v-skeleton-loader
        class="mr-auto ml-3 reels-loading-card"
        type="image"
        ></v-skeleton-loader>

        <v-skeleton-loader
        class="mr-auto ml-3 reels-loading-card"
        type="image"
        ></v-skeleton-loader>

        <v-skeleton-loader
        class="mr-auto ml-3 reels-loading-card"
        type="image"
        ></v-skeleton-loader>

        </div>
    </div>

    <div v-else-if="reels.length == 0" class="no-reels-container">
        <div class="no-reels-thumbnail">
          <img  src="https://firebasestorage.googleapis.com/v0/b/poc-project-309214.appspot.com/o/no-reels-thumbnail.svg?alt=media&token=c8d34188-c7df-42fd-8dd2-020c8d07675f" class="no-reels-image" />
        </div>
        
        <div class="no-reels-description">
          <p>
            Create reels and share key moments
          </p>
          <span>
            There are no reels in your workspace yet
          </span>
        </div>

        <div class="no-reels-cta">
          <button class="explore-projects-reel-button" @click.stop="openProjectsPage()" >
              Explore projects <i class="ri-share-box-line"></i>
          </button>
        </div>

    </div>

    <div v-else class="card-list">
      <div class="controls ">
      <div class="header">
      <input
        type="text"
        v-model="searchQuery"
        placeholder="Search reels..."
        class="search-bar"
      />

    </div>

      </div>

      <div v-if="filteredreels.length == 0">        
        <div class="no-reels-description">
          <p>
            No results found
          </p>
        </div>
      </div>

      <div v-for="card in filteredreels" :key="card.id" @click="viewCard(card.id)" class="card"
        @mouseenter="hover = card.id"
        @mouseleave="hover = null"

      >

        <div v-if="card.first_clip_data">
        <media-player
            class="card-thumbnail"
            :src="card.first_clip_data.transcript_media_url" :clip-start-time="card.first_clip_data.timestamps.start" :clip-end-time="card.first_clip_data.timestamps.start">
            <media-provider></media-provider>
        </media-player>
        </div>
        <div v-else>
            <img  src="https://firebasestorage.googleapis.com/v0/b/poc-project-309214.appspot.com/o/empty-reel-thumbnail.svg?alt=media&token=28fb7af9-2957-4b82-a14e-81a2b42a7542" class="empty-card-thumbnail" />
        </div>
        <div class="card-content">

          <div class="card-header">
            <h3 class="card-title">{{ card.title }}</h3>
            <div class="card-header-actions" v-show="hover == card.id">
                <button class="card-copy-reel-link" @click.stop="copyReelLink(card)" >
                    <i class="ri-link"></i> Copy link
                </button>


                <button  v-if="isWorkspaceEditor" @click.stop="handleReelDeletion(card)"  class="delete-card-btn">
                <i class="ri-delete-bin-6-line"></i>
            </button>
            </div>
           </div>



          <div class="card-sub-title">
          <p class="created-by-info-text"> <i class="ri-user-line"></i> {{ card.createdBy }}</p>
          <div class="card-num-of-notes">
            <i class="ri-film-line"></i>
            {{ card.num_of_notes }}
           </div>
          </div>
        </div>

      </div>
      <v-snackbar
      color="#5b7edb"
      shaped
      v-model="reelCopySnackbar"
      :timeout="1500"
    >
      Reel link copied!

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          dark
          v-bind="attrs"
          @click="reelCopySnackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      color="#5b7edb"
      v-model="reelDeleteSnackbar"
      :timeout="2000"
    >
      Reel deleted!

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          dark
          v-bind="attrs"
          @click="reelDeleteSnackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>


    <LoopActionModal
          :showModal="showDeleteConfirmationModal"
          title="Delete reel?"
          ctaEvent="confirm-delete"
          ctaBtnColor="#C53030"
          ctaText="yes"
          v-on:confirm-delete="confirmDelete"
          v-on:toggle-modal="toggleDeleteModal"
        >
          <template v-slot:content>
            <p class="projects-deletion-text mb-7">
              Are you sure you want to delete
              <span class="projects-deletion-text__Strong">
                "{{ reelToDelete.title }}" reel?
              </span>
            </p>
          </template>
        </LoopActionModal>


    </div>
  </template>
  
  <script>
  import { mapState } from 'vuex';
  import ReelsAPI from "./reels"
  import LoopActionModal from '@/components/modals/LoopActionModal.vue';
  import { ReelEvents } from './events';
  export default {
    name: 'Reels',
    components: {LoopActionModal},
    data() {
      return {
        isLoadingPage:true,
        reels: [],
        searchQuery: '',
        selectedProject: '',
        reelCopySnackbar:false,
        reelDeleteSnackbar:false,
        showDeleteConfirmationModal: false,
        reelToDelete: null,
        hover:null
      };
    },
    computed: {
        ...mapState(["userProfile"]),
      filteredreels() {
        return this.reels.filter(card => {
          const matchesSearch = card.title.toLowerCase().includes(this.searchQuery.toLowerCase());
          const matchesProject = this.selectedProject ? card.project === this.selectedProject : true;
          return matchesSearch && matchesProject;
        });
    },
    isWorkspaceEditor(){
      if (this.userProfile.workspace_role === 'editor') {
        return true
      } else {
        return false
      }
    }
    },
    async created(){ 
        const reelsListResponse = await ReelsAPI.getReelsForUser(this.userProfile.id,this.userProfile.workspace_id)
        // console.log('[debug reels]',reelsListResponse)
        if (reelsListResponse.status == 'success') {
            this.reels = reelsListResponse.data.reels
        } else {
            this.reels = []
        }
        this.isLoadingPage = false;

        window.analytics.page("Reels");
    },
    methods: {
      openProjectsPage(){
        this.$router.push('/projects')
      },   
      viewCard(cardId) {
        this.$router.push({ name: 'Reel', params: { id: cardId } });

        window.analytics.track(ReelEvents.CLICKED_REEL_CARD, {
          email: this.userProfile.email,
        });
      },
      copyReelLink(card){
        navigator.clipboard.writeText(
        `${window.origin}/share/reel/${card.id}`
        );
        this.reelCopySnackbar = true;
        window.analytics.track(ReelEvents.CLICKED_COPY_LINK_REEL_CARD, {
          email: this.userProfile.email,
        });
      },
      async handleReelDeletion(card){
        this.reelToDelete = card
        this.showDeleteConfirmationModal = !this.showDeleteConfirmationModal

      },
      toggleDeleteModal(){
        this.showDeleteConfirmationModal = !this.showDeleteConfirmationModal
        window.analytics.track(ReelEvents.CLICKED_DELETE_REEL_CARD, {
          email: this.userProfile.email,
        });
      },
      confirmDelete(){
        this.deleteCard(this.reelToDelete)
      },
      async deleteCard(reelCard) {
        this.reels = this.reels.filter(card => card.id !== reelCard.id);

        const payload = {
            user_id: this.userProfile.id,
            workspace_id: this.userProfile.workspace_id,
            reel_id: reelCard.id 
        };
        // console.log('reel payload',payload)
        const deleteReelResponse = await ReelsAPI.deleteReel(payload);
        // console.log(deleteReelResponse)
        if (deleteReelResponse.status === 'success'){
            // alert("Delete success!")
            this.reelDeleteSnackbar = true;
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .no-reels-container{
    margin-top:auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  
  .no-reels-thumbnail{
    display: flex;
    width: 240px;
    height: 113.023px;
    padding: 0px 1.119px 0px 0.698px;
    justify-content: center;
    align-items: center;
  }
  .no-reels-description{
    margin-top: 32px;
    text-align: center;
    font-family: Inter;
    font-style: normal;
    line-height: 150%; /* 21px */

  }
  .no-reels-description p{
    color: var(--Neutrals-600, #4C4C4C);
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0px;
  }
  .no-reels-description span{
    color: var(--Neutrals-400, #999);
    font-size: 14px;
    font-weight: 500;
  }
  .explore-projects-reel-button{
    margin-top: 20px;
    display: flex;
    height: 45px;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    border-radius: 44px;
    background: var(--Primary-500, #5B7EDB);
    color: var(--Neutrals-White, #FFF);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 21px */
    letter-spacing: -0.14px;
    text-transform: capitalize;
  }
  .reels-loading-search{
    background-color: #EDEDED !important;
    color: #EDEDED;
    margin-bottom: 24px;
    max-width: 200px;
    height: 34px;
    border-radius: 12px;
  }
  .reels-loading-card{
    background-color: #EDEDED !important;
    color: #EDEDED;
    margin-bottom: 24px;
    max-width: 750px;
    height: 116px;
    border-radius: 12px;
  }
  .card-copy-reel-link{
    display: flex;
    padding: 4px 8px 4px 6px;
    align-items: center;
    gap: 4px;
    border-radius: 20px;
    background: var(--Primary-200, #F6F8FE);
    color: var(--Primary-500, #5B7EDB);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 18px */
  }
  .card-copy-reel-link:hover{
    background-color: #E8EDFC;
  }
  .card-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:6px;
  }
  .card-header-actions{
    margin-left:auto;
    display:flex;
    gap:6px;
  }
  .card-num-of-notes{
    overflow: hidden;
    color: var(--Neutrals-300, #B2B2B2);
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 18px */
  }
.card-sub-title{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:4px;
}

.card-sub-title svg{
    width: 14px;
    height: 14px;
}
 .created-by-info-text{
    overflow: hidden;
    color: var(--Neutrals-300, #B2B2B2);
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 18px */
    margin-bottom: 0px;
 } 

.delete-card-btn{
    display: flex;
    width: 26px;
    height: 26px;
    padding: 4px 6px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    font-size: 14px !important;
    border-radius: 4px;
    background: var(--Accent-Red-Light, #FFF5F5);
}

.delete-card-btn i {
    display: flex;
    width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: #C53030;
}

.delete-card-btn:hover{
    background:  rgba(197, 48, 48, 0.1);
}
.header {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
}


.sticky {
    position: sticky;
    top: 0; /* Distance from the top of the viewport */
    background-color: #f4f4f4;
    padding: 10px;
    z-index: 1000; /* Optional, to ensure it stays on top of other elements */
    border-bottom: 2px solid #ccc;
}

.add-card-button {
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  background: #ffffff;
    border-radius: 8px;
    color: #5B7EDB !important;
}

.add-card-button:hover {
  background-color: #F6F8FE;
}

.add-card-form {
  /* margin-bottom: 16px;
  padding: 16px;
  border: 1px solid var(--Neutrals-200, #EDEDED);
  border-radius: 8px;
  background: var(--Neutrals-White, #FFF); */
}

  .card-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 80px;
    max-width: 720px;
  }
  
  .controls {
    display: flex;
    gap: 12px;
  }
  
  .search-bar {
    padding: 6px;
    border: 1px solid var(--Neutrals-200, #EDEDED);
    border-radius: 8px;
    flex: 1;
    max-width: 15.5em;
    margin-right: auto;
    overflow: hidden;
    color: var(--Neutrals-300, #B2B2B2);
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin-bottom: 0px;
  }

  .filter-menu {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .card {
    display: flex;
    padding: 8px;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid var(--Neutrals-200, #EDEDED);
    background: var(--Neutrals-White, #FFF);
    max-width: 720px;
    min-height: 116px;
    cursor: pointer;
    transition: box-shadow 0.2s;
  }
  
  .card:hover {
    border-radius: 12px;
    border: 1px solid var(--Primary-400, #D2DCFB);
    background: var(--Neutrals-White, #FFF);

    /* Shadow- L1 */
    box-shadow: 0px 0px 8px 0px rgba(102, 102, 102, 0.15);
  }
  
  .card-thumbnail {
    display: flex;
    width: 177px;
    height: 100px;
    flex-direction: column;
    align-items: center;
    gap: 7.989px;
    border-radius: 4px;
  }

  .empty-card-thumbnail{
    display: flex;
    align-items: center;
    width: 177px;
    height: 100px;
    flex-direction: column;
    align-items: center;
    gap: 7.989px;
    border-radius: 4px;
    background: #F6F8FE;
    color: #5B7EDB
  }
  .empty-card-thumbnail i{

    margin: auto;
  }
  
  .card-content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .card-content i {
    width: 16px;
    height: 16px;
  }
  
  .card-title {
    overflow: hidden;
    color: var(--Neutrals-600, #4C4C4C);
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
  }
  
.input-card-title {
    overflow: hidden;
    color: var(--Neutrals-600, #4C4C4C);
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
}
  </style>