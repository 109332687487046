<template>
    <div>
      <!-- Render the component regardless -->
      <AITagsLoadingState :isGeneratingAIInsights="generatingAIInsights" v-if="currentPage!='editor' && currentPage!='Project Analysis' && (generatingAITags || assigningAITags || generatingAIInsights)"/>

      <Snackbar
        v-if="showAutoTaggingFinishedSnackbar"
        :showRedirectCTA="showSnackbarRedirectCTA"
        :showSnackbar="showAutoTaggingFinishedSnackbar"
        :renderSuccessTick="true"
        message="Auto-tagging done"
        :timeout="5000"
        redirectCTAEventName="redirect-autotagging-snackbar"
        redirectCTAMessage="See Auto-Tags"
        v-on:redirect-autotagging-snackbar="handleAutoTaggingRedirect"
      />

      <Snackbar
        v-if="showAIInsightGeneratedSnackbar"
        :showRedirectCTA="showSnackbarRedirectCTA"
        :showSnackbar="showAIInsightGeneratedSnackbar"
        :renderSuccessTick="true"
        message="Auto-analysis done"
        :timeout="5000"
        redirectCTAEventName="redirect-autotagging-snackbar"
        redirectCTAMessage="See Auto-Tags"
        v-on:redirect-autotagging-snackbar="handleAutoTaggingRedirect"
      />
      <slot></slot>
    </div>
  </template>
  
  <script>

  // imports
  import ProjectAPI from '@/api/projects/projects';
  import { mapState } from "vuex";
  import {auth, projectsCollection} from '@/firebase'
  import AITagsLoadingState from '@/components/loaders/AITagsLoadingState.vue';
  import TagsAPI from '@/api/tags/tags';
  import Snackbar from '@/components/notifications/Snackbar.vue';

  // non-reactive data 
  export default {
  components: { AITagsLoadingState, Snackbar },
    name: 'ProjectStateWrapper',
    data() {
      return {
        currentPage: null,
        projectStateLoading: true,          //  loading state for project
        currentUser: auth.currentUser,      // Currently logged in user
        generatingAITags: false,            // Flag to handle the loading state when AI tags are generating
        assigningAITags: false,             // Flag to handle the loading state when AI tags are assigning
        projectId: null,                    // Project Id of the project user is on
        projectData: null,                  // Project Data of the project page user is on
        tagIdToTypeMapping: {},             // Hashmap to retrive tag type for a tag Id used accross various project pages (Analysis and Editor)
        validateAutoTaggingCTA:null,            // Flag to show/hide AutoTagging CTA
        showAutoTaggingFinishedSnackbar:false,  // Flag to show/hide AutoTagging snackbar
        showSnackbarRedirectCTA: false,
        generatingAIInsights: false, // Flag to handle loading state when AI Insights are generating
        showAIInsightGeneratedSnackbar: false // Flag to handle AI Insight generation snackbar
      };
    },
    // Provide API allows a child component to access a parent component's data without passing them as props
    provide() {
        const projectStateData = {}

        Object.defineProperties(projectStateData, {
          "generatingAITags": { enumerable: true, get: () => this.generatingAITags, },
          "assigningAITags": { enumerable: true, get: () => this.assigningAITags, },
          "tagIdToTypeMapping" : { enumerable: true, get: () => this.tagIdToTypeMapping},
          "validateAutoTaggingCTA" : { enumerable: true, get: () => this.validateAutoTaggingCTA},
          "generatingAIInsights" : { enumerable: true, get: () => this.generatingAIInsights}
        })

        return {
            projectStateData,
        }
    },
    computed: {
      ...mapState([
        "userWorkspace",
        "userProfile"
      ]),
    },
    created() {
        this.initializeState()
        this.currentPage = this.$router.currentRoute.name
        console.log("[project state] initializeProjectData called",this.currentPage)
        if(this.currentPage.includes('Project')){
            this.projectId = this.$router.currentRoute.params.projectId
            console.log("[project state] ",this.projectId)
        }else if(this.currentPage == 'editor'){
            this.projectId = this.$router.currentRoute.query.projectId
        }
        // this.initializeProjectData()
        console.log('[project state] projectId: ',this.projectId)
        this.populatetagIdToTypeMapping()
        // LISTENER
        this.unsubscribeProjectDoc = projectsCollection.doc(this.projectId).onSnapshot(async(doc) => {
            console.log('[project state] project doc changed: ',doc.data())
            const projectDocData = doc.data()

            // If generatingAITags changes from true to false, update it after a delay
            if(this.generatingAITags == true && projectDocData['generatingAITags']==false){
              setTimeout(()=>{
                this.generatingAITags = projectDocData['generatingAITags']
              },15000)
            }else{
              // If generatingAITags changes from false to true, update it immediately
              this.generatingAITags = projectDocData['generatingAITags'] 
            }

            if(this.assigningAITags == true && projectDocData['assigningAITags']==false){
              this.showAutoTaggingFinishedSnackbar = true;
              this.showSnackbarRedirectCTA = true
              if (this.currentPage == 'Project Analysis'){
                this.showSnackbarRedirectCTA = false
              }
              setTimeout(()=>{
                this.showAutoTaggingFinishedSnackbar = false;
              },60000)
            }

            // if generatingAIInsights changes from true to false & AutoTagging is complete 
            if(this.generatingAIInsights == true && projectDocData['generatingAIInsights']==false){
              this.showAIInsightGeneratedSnackbar = true;
              this.showSnackbarRedirectCTA = false
              setTimeout(()=>{
                this.showAIInsightGeneratedSnackbar = false;
              },60000)
            }
            // If assigningAITags changes from true to false, reload the page
            // if(this.currentPage == 'Project Analysis'){
            //   if(this.assigningAITags == true && projectDocData['assigningAITags']==false){
            //     window.location.reload()
            //   }
            // }
            this.assigningAITags = projectDocData['assigningAITags']
            this.generatingAIInsights =  projectDocData['generatingAIInsights']
            // Hide auto tagging nudge while auto tagging is in progress
            if (this.assigningAITags || this.generatingAITags){
              this.validateAutoTaggingCTA = null;
            }
        }, error => {
            console.error("[DB_READ_ERROR] [project state] Error fetching messages: ", error);
        });
        // Check if we can show/hide autotagging cta
        this.validateAutoTaggingAInotes()
    },
    methods: {
      handleAutoTaggingRedirect(){
        console.log('handleAutoTaggingRedirect',this.projectId)
        this.$router.push(`/project/${this.projectId}/analysis?tab=tag&openAutoTaggingPopUp=true`)
      },
      async populatetagIdToTypeMapping(){
        // Get all tags regardless of user since this is only for view-only data
        const tags = await TagsAPI.getAllTags(this.projectId,true)
        console.log('[project state] tags: ', tags)
        let _tagIdToTypeMapping = {}
        tags.forEach(tag=>{
          _tagIdToTypeMapping[tag.id] = tag.type ? tag.type : 'manual'
        })
        this.tagIdToTypeMapping = _tagIdToTypeMapping
      },
      // Use this function to get the project id and project document data 
      async initializeProjectData(){
        const project = await ProjectAPI.getSingleProjectById(this.projectId)
        this.projectData = project;
      },
      initializeState(){
        this.generatingAITags = false;
        this.projectId = null;
        this.projectData = null;
        this.assigningAITags = false;
        this.generatingAIInsights = false;
      },
 
    },
    watch: {
      $route: {
        immediate: true,
        handler() {
          this.projectStateLoading = true;
          this.initializeState()
        }
      }
    }
  };
  </script>

  