import axios from "axios"
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import * as fb from '../firebase'
import router from '../router'
import firebase from 'firebase/app';
import QuestionScriptsAPI from '../api/question-script/question-scripts';
import CalendarAPI from '../api/calendar/calendar';
import WorkspaceAPI from '@/api/workspace/workspace'
import BillingAPI from "@/api/BillingAPI"
import UserAPI from '@/api/user/user';
import TranscriptionDurationAPI from "@/api/TranscriptionDurationAPI"
import { snapshot } from "yjs"
import TransactionalEmailAPI from '@/api/TransactionalEmailAPI'
import * as Sentry from "@sentry/vue";
import { getTranscriptDocument } from "@/api/sessions/sessions"
import {getFunctionUrl} from '@/functions/functionsConfig'
import { db } from "../firebase"

Vue.use(Vuex)
const store = new Vuex.Store({
    plugins: [createPersistedState({
        key: 'vuex',
        reducer(val) {
            if (val.isUserLoggedIn === false) {
                return {}
            }
            return val
        }
    })],
    state: {
        collabData:null,                    // TODO: Make this redundant and replace this with projectCollaborator state
        isUserLoggedIn: false,
        userProfile: {},
        tagList: [],
        tagDataList: [],
        tagNames: [],
        zoomIntegration: null,
        currentRecording: {},
        recordingBot:{
            id:null,
            status:false,
            statusName:null,
        },
        defaultProject:null,
        selectedScriptForNotetaking:null,
        isSidenavOpen:true,
        migratingWorkspace: false,
        autoGenInfoModalDefault:true,
        showUploadModal:false,
        showImportRawTextModal: false,
        showCreateNewProjectModal: false,
        checkScriptNudge: false,
        showEmailVerificationBanner: false,
        userWorkspace:null,
        isSSOlinked:false,
        // hasZoomSupport: false,
        // annotations: [],
        // zoomRecordingsList: [],
        // currentRecording: {
        //     "event": "recording.started",
        //     "event_ts": 1234566789900,
        //     "payload": {
        //       "account_id": "lAAAAAAAAAAAAA",
        //       "object": {
        //         "uuid": "dj12vck6sdTn6yy7qdy3dQg==",
        //         "id": 150000008,
        //         "host_id": "uLobbbbbbbbbb_qQsQ",
        //         "topic": "A test meeting",
        //         "type": 2,
        //         "start_time": "2019-07-11T20:00:00Z",
        //         "duration": 1,
        //         "timezone": "America/Los_Angeles",
        //         "recording_file": {
        //           "recording_start": "2019-07-31T22:41:02Z",
        //           "recording_end": ""
        //         }
        //       }
        //     }
        // },
        trialEnded: false,
        subscriptionActive: true,
        plan:null,
        workspaceMigrationNames:{
            from: null,
            to: null
        },
        billingDetails: {
            plan: "starter",
            planEndDate: null,
            planDuration: null,
            subscriptionActive: true,
            trialDaysLeft: 0,
            trialEnded: true
        },
        remainingTranscriptionDuration: null,
        showUpgradePlanModal: false,
        searchEnabled: false,
        enableFBListeners:false, // Track enable/disable state for firestore listeners
        isAuthInProgress:false, // Track if auth flow is in progress
        disableFileUploadAINotes: false,
        authMode:null, // Tracks the auth mode for a user during login and signup
        skipEmailVerification:false, // Indicates if email verification is skipped for the session
        projectCollaborator:null,
        isIdTokenUpdated:null
        // projectCollaborator: {          // Project collaborator state: includes projectId, collaborator Id and collaborator role
        //     isCollaborator: false,      // Whether this project has a collaborator or not
        //     access: null,               // Project access role for the collaborator
        //     projectId: null,            // Project Id where the collaborator is collaborating
        //     ownerUserId: null,          // User Id of the project owner
        //     ownerUserName : null,       // User name for the project owner
        //     resourceWorkspaceId: null,  // Workspace Id used for all queries for a collaborator
        // } 
    },
    mutations: {
        SET_ID_TOKEN_STATE(state,val){
            state.isIdTokenUpdated = val
        },
        SET_SKIP_EMAIL_VERIFICATION(state,val){
            state.skipEmailVerification = val
        },
        SET_AUTH_MODE(state,val){
            state.authMode = val
        },
        RESET_USER_PROFILE(state) {
            state.userProfile = {}
            state.isAuthInProgress = false // Reset auth progress state
        },
        SET_AUTH_IN_PROGRESS(state, inProgress) {
            state.isAuthInProgress = inProgress;
        },
        setWorkspaceMigrationNames(state,val){
            state.workspaceMigrationNames = val;
        },
        setMigratingWorkspace(state,val){
            state.migratingWorkspace = val
        },
        setAutoGenInfoModalDefault(state, val){
            state.autoGenInfoModalDefault = val
        },
        setBotStatus(state, val) {
            state.recordingBot = val
        },
        setUserLoginStatus(state, val) {
            state.isUserLoggedIn = val
        },
        setCollabData(state,val){
            state.collabData = val
        },
        setUserProfile(state, val) {
            state.userProfile = val
            state.isAuthInProgress = false // Reset auth progress state
        },
        setAnnotations(state, val) {
            state.annotations = val
        },
        setTags(state, val) {
            state.tagList = val
        },
        setTagData(state, val) {
            state.tagDataList = val
        },
        setTagNames(state, val) {
            state.tagNames = val
        },
        setZoomIntegration(state, val) {
            state.zoomIntegration = val
        },
        // setZoomSupportStatus(state, val) {
        //     state.hasZoomSupport = val
        // },
        setZoomRecordingsList(state, val) {
            state.zoomRecordingsList = val
        },
        setCurrentRecording(state, val) {
            state.currentRecording = val
        },
        setDefaultProject(state, val) {
            state.defaultProject=val
        },
        setSelectedScriptForNotetaking(state, val) {
            state.selectedScriptForNotetaking = val
        },
        setUserWorkspace(state,val){
            state.userWorkspace = val
        },
        setSearchEnabled(state,val){
            state.searchEnabled = val
        },
        toggleSidenavOpen(state,val){
            state.isSidenavOpen=val;
        },
        toggleUploadModal(state,val){
            state.showUploadModal=val;
        },
        toggleImportRawTextModal(state,val){
            state.showImportRawTextModal=val;
        },        
        toggleCreateNewProjectModal(state,val){
            state.showCreateNewProjectModal=val;
        },
        toggleCeckScriptNudge(state,val){
            state.checkScriptNudge = val
        },
        toggleEmailVerificationBanner(state,val){
            state.showEmailVerificationBanner = val
        },
        setProjectCollaborator(state,val){
            state.projectCollaborator = val;
        },
        updateBillingDetails(state, val){
            state.billingDetails = val

            //attach billing details to user analytics profile
            // console.log("committing...",fb.auth.currentUser.email, val)
            const billingDetails = val
            var userProperty = {
                plan: billingDetails.plan,
                subscription_active: billingDetails.subscriptionActive,
                trial_active: !billingDetails.trialEnded,
            }
            billingDetails.planDuration ? userProperty['billing_cycle'] = billingDetails.planDuration : ""
            // console.log("userProperty", userProperty, this.state.userWorkspace)
            window.analytics.identify(fb.auth.currentUser.email, userProperty)

            //attach billing details to user's june workspace if its a company workspace
            const userWorkspace = this.state.userWorkspace
            if(userWorkspace && userWorkspace.type == 'company'){
                let workspaceId = userWorkspace.id
                //remove trial info
                window.analytics.group(workspaceId,{
                    plan: userWorkspace.plan,
                    billing_cycle: userWorkspace.planDuration ? userWorkspace.planDuration : null,
                    subscription_active: userWorkspace.subscriptionActive,
                });
            }
        },
        updateTrialEnded(state, val){
            state.trialEnded = val
        },
        updateSubscriptionActive(state, val){
            state.subscriptionActive = val
        },
        updatePlan(state, val){
            state.plan = val
        },
        updateDisableFileUploadAINotes(state,val){
            state.disableFileUploadAINotes = val
        },
        updateRemainingTranscriptionDuration(state, val){
            state.remainingTranscriptionDuration = val
        },
        toggleUpgradePlanModal(state,val){
            state.showUpgradePlanModal = val
        },
        setSSOlinkingStatus(state, val){
            state.isSSOlinked = val
        },
        setEnableFBListeners(state,val){
            state.enableFBListeners = val;
        },
    },
    actions: {
        // Fetch user data on page load
        async fetchUserDataOnLoad({ commit, dispatch, state}) {
            fb.auth.onAuthStateChanged(async user => {
              if (user) {
                // Check if any auth flow is already in progress
                if (!state.isAuthInProgress){
                    console.log('user is logged in fetching data')
                    // Mark auth flow as in progress
                    commit('SET_AUTH_IN_PROGRESS', true);
                    // Set user login status
                    commit('setUserLoginStatus',true)
                    // initialize user session
                    await dispatch('initializeUserSession', user)
                }
              } else {
                console.log('user is not logged in')
                commit('RESET_USER_PROFILE');
                commit('setEnableFBListeners',false)
              }
            });
        },
        setEnableFBListeners({commit},value){
            commit('setEnableFBListeners',value)
        },
        //Analysis user access cleanup on route changed from analysis page
        async removeActiveAnalysisUser({commit},value){
            console.log("reached here -> route changed from analysis to non analysis page, analysis access cleanup")
            // Update current viewer when user navigates from analysis to other pages
            try{
                //set currentViewer to null
                console.log('reached here 1 - removing analysis user')
                const projectId = value

                //Send data using sendBeacon
                const payload = JSON.stringify({
                    "action": "REMOVE_ACCESS",
                    "user_id": fb.auth.currentUser.uid,
                    "project_id": projectId
                });
                // the cleanup logic to remove analysis access is moved to backend
                // BACKEND_API
                navigator.sendBeacon(getFunctionUrl('manage_analysis_user_access'), payload);
            }
            catch(err){
                console.log('error while removing active analysis user', err)
            }            
        },
        setSSOlinkingStatus({commit},value){
            commit('setSSOlinkingStatus',value)
        },
        setProjectCollaborator({commit},value){
            commit('setProjectCollaborator',value) 
        },
        setUserWorkspace({commit},value){
            commit('setUserWorkspace',value)
        },
        setWorkspaceMigrationNames({commit},value){
            commit('setWorkspaceMigrationNames',value)
        },
        setMigratingWorkspace({commit},value){
            commit('setMigratingWorkspace',value)
        },
        setSearchEnabled({commit},value){
            commit('setSearchEnabled',value)
        },
        toggleEmailVerificationBanner({commit},value){
            commit('toggleEmailVerificationBanner',value)
        },
        toggleCheckScriptNudge({commit},value){
            commit('toggleCeckScriptNudge',value)
        },
        toggleUploadModal({commit},value){
            commit('toggleUploadModal',value)
        },
        toggleCreateNewProjectModal({commit},value){
            commit('toggleCreateNewProjectModal',value)
        },
        toggleSidenavOpen({commit},value){
            commit('toggleSidenavOpen',value)
        },
        toggleImportRawTextModal({commit},value){
            commit('toggleImportRawTextModal',value)
        },
        updateBillingDetails({commit}, value){
            commit('updateBillingDetails',value)
        },
        updateRemainingTranscriptionDuration({commit}, value){
            commit('updateRemainingTranscriptionDuration', value)
        },
        toggleUpgradePlanModal({commit}, value){
            commit('toggleUpgradePlanModal', value)
        },
        SET_AUTH_IN_PROGRESS({commit}, value){
            commit('SET_AUTH_IN_PROGRESS', value)
        },
        SET_AUTH_MODE({commit}, value){
            commit('SET_AUTH_MODE', value)
        },
        SET_SKIP_EMAIL_VERIFICATION({commit},value){
            commit('SET_SKIP_EMAIL_VERIFICATION',value)
        },
        // Send sentry alert
        //       this.$store.dispatch("sendAlert", {workflow:'onboarding', error: e})
        sendAlert({ dispatch, commit }, data){
            console.log('sendAlert', data)
            // Extract error message and workflow
            const {error,workflow} = data
            Sentry.withScope(scope => {
                // Set basic context that doesn't require auth
                scope.setTag("workflow", workflow);
                scope.setExtra("route", window.location.href);
                
                // Add user context if available
                if (fb.auth.currentUser) {
                    scope.setContext({
                        email: fb.auth.currentUser.email,
                    });
                    scope.setUser({
                        id: fb.auth.currentUser.uid,
                        username: fb.auth.currentUser.email,
                    });
                    scope.setExtra("email", fb.auth.currentUser.email);
                } else {
                    scope.setContext({
                        auth_status: 'unauthenticated'
                    });
                    scope.setUser({
                        id: 'anonymous'
                    });
                }

                console.log('scope', scope)
                Sentry.captureException(error);
            });
        },
        // Send user to the mailing list
        async triggerZapierWebhook({ dispatch, commit, state }, data){
            // Extract user details
            const user_id = data.user_id
            const user_profile = data.user_profile

            // Trigger zapier request
            // BACKEND_API
            const zapier_webhook_url= getFunctionUrl('add_signup_email_zapier_webhook')

            let req_data = {
                "email": `${user_profile.email}`,
                "first_name": `${user_profile.name}`,
                "user_id":`${user_id}`
            }

            // Attach company if any
            if (user_profile.company_name){
                req_data.company = user_profile.company_name
            }

            let requestConfig = {
                method: 'post',
                maxBodyLength: Infinity,
                url: zapier_webhook_url,
                headers: { 
                'Content-Type': 'application/json'
                },
                data : JSON.stringify(req_data)
            };
            const zapier_webhook_response = await axios(requestConfig)
            console.log({zapier_webhook_response})
        },
        // Link ph user and trigger brewnote migration
        async link_ph_user({ dispatch, commit, state }, data){
            // Extract user_id and fingerprint
            const user_id = data.user_id
            const fingerprint = data.fingerprint
            // BACKEND_API
            const link_ph_user_endpoint = getFunctionUrl('link_ph_user')
            var payload_link_ph_user = JSON.stringify({
                user_id: user_id,
                ph_user_id: fingerprint,
            });
            console.log("link brewnote user payload", payload_link_ph_user)
            var config_link_ph_user = {
                method: "post",
                url: link_ph_user_endpoint,
                headers: {
                    "Content-Type": "application/json",
                },
                data: payload_link_ph_user,
            };
            const link_ph_user_response = await axios(config_link_ph_user)
            console.log({link_ph_user_response})
        },
        async ssoSignIn({ dispatch, commit }, data) {
            // Mark auth flow as in progress
            commit('SET_AUTH_IN_PROGRESS', true);

            // Create workos oidc provider
            var provider = new firebase.auth.OAuthProvider('oidc.workos')
            provider.setCustomParameters({
                organization: data.orgId,
            });
            // Start login flow 
            firebase.auth()
            .signInWithPopup(provider)
            .then((result) => {
                // console.log('result',result)
                var credential = result.credential;
                var token = credential.accessToken;
                var user = result.user;

                // Get oidc Idp information for workos
                const workosUserProfile = result.additionalUserInfo.profile
                const displayName = workosUserProfile.first_name + ' ' + workosUserProfile.last_name

                // Create an account or login by checking if user doc exists
                var userRef = fb.usersCollection.doc(user.uid);
                userRef.get().then(async(doc) => {
               
                if (doc.exists) { // If user record exists
                    // Set auth mode
                    commit('SET_AUTH_MODE', 'login');
                    // initialize user session
                    store.dispatch('initializeUserSession', user)

                    fb.usersCollection.doc(user.uid).update({
                        workosUserProfile: workosUserProfile
                    }).then(()=>{
                        // Send analytics event
                        window.analytics.identify(user.email, {
                            email: user.email,
                        });
                        window.analytics.track("login", {
                            email: user.email,
                            provider: 'workos_oidc'
                        });
                    })
                } else { // Create user record
                    // Set auth mode
                    commit('SET_AUTH_MODE', 'signup');

                    // Generate notetaking id
                    const genRanHex = size => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');

                    // Create analytics events
                    let signupEventParams = {
                        email: user.email.toLowerCase(),
                        provider: 'workos_oidc'
                    }

                    let identifyCallParams = {
                        email: user.email.toLowerCase(),
                        plan: 'Teams',
                        subscription_active: false,
                        trial_active: true,
                    }

                    // Create user record
                    let userDocument = {
                        name: displayName,
                        email: user.email,
                        notetaking_room_id: genRanHex(10),
                        createdOn: new Date(),
                        updatedOn: new Date(),
                        workosUserProfile: workosUserProfile,
                        emailVerified: true,
                        trialEnded: false,
                        searchEnabled: true,
                        claims_last_updated_at: null
                    }
        
                    // Check brewnote fingerprint
                    if(data.fingerprint){
                        userDocument.phUserId = `${data.fingerprint}`
                    }

                    // Check referrer_id 
                    if(data.referrer_id){
                        userDocument.referrer_id = data.referrer_id
                        identifyCallParams.looppanel_referrer_id = data.referrer_id
                        signupEventParams.looppanel_referrer_id = data.referrer_id
                        const referrer = await UserAPI.getUserEmailFromReferrerId(data.referrer_id)
                        if(referrer.status == 'success'){
                            signupEventParams.looppanel_referrer = referrer.referrer_email
                            identifyCallParams.looppanel_referrer = referrer.referrer_email
                        }
                    }

                    // Write user record to the db (usersCollection)
                    fb.usersCollection.doc(user.uid).set(userDocument).then(()=>{

                        // initialize user session
                        store.dispatch('initializeUserSession', user)

                        // Trigger mailchimp mailing list
                        store.dispatch('triggerZapierWebhook', {'user_id':user.uid,'user_profile': userDocument})

                        // Trigger brewnote linking
                        if (data.fingerprint){
                            store.dispatch('link_ph_user',{'user_id':user.uid, 'fingerprint': data.fingerprint})
                        }

                        // Send analytics events
                        // Add your own unique ID here or we will automatically assign an anonymousID
                        window.analytics.identify(user.email, identifyCallParams);
                        window.analytics.track("signup", signupEventParams);
                    })

                }
                }).catch((error) => {
                    console.log("Error getting user document for sso login", error);
                    dispatch("sendAlert", {workflow:'onboarding', error: error})
                });


                }).catch((error) => {
                    console.log('errorSSO',error)
                    dispatch("sendAlert", {workflow:'onboarding', error: error})
                    // Handle Errors here.
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    // The email of the user's account used.
                    var email = error.email;
                    // The firebase.auth.AuthCredential type that was used.
                    var credential = error.credential;
                      // User's email already exists.
                    // if (error.code === 'auth/account-exists-with-different-credential') {
                    //     // The pending WorkOS oidc credential.
                    //     var pendingCred = error.credential;
                    //     // The provider account's email address.
                    //     // Get the sign-in methods for this email.
                    //     fb.auth.fetchSignInMethodsForEmail(email).then(methods => {
                    //         // console.log('methods',methods)
                    //         const method = methods[0]
                    //         // Link with google provider
                    //         if (method == "google.com") {
                    //             var provider = new firebase.auth.GoogleAuthProvider();
                    //             fb.auth.signInWithPopup(provider).then(result => {
                    //                 result.user.linkWithCredential(pendingCred).then(usercred => {
                    //                   // Success.
                    //                   console.log('account linked')
                    //                   router.push('/')
                    //                 });
                    //             });
                    //         }
                    //     });
                    // }
                });
            },
        linkExistingUserToSSO({ dispatch, commit }, data){
            // BACKEND_API
            const requestUrl = getFunctionUrl('get_sso_org_info')
            // const requestUrl="http://127.0.0.1:8081"
            let config = {
                method: 'post',
                url: requestUrl,
                headers: { 
                'Content-Type': 'application/json'
                },
                data : JSON.stringify({
                "email": fb.auth.currentUser.email,
                })
            };
            axios.request(config)
            .then((response) => {
                if (response.data.message == 'success'){
                    const orgId = response.data.orgId
                    var provider = new firebase.auth.OAuthProvider('oidc.workos')

                    provider.setCustomParameters({
                        organization: orgId,
                    });
        
                    fb.auth.currentUser.linkWithPopup(provider).then((result) => {
                        // Accounts successfully linked.
                        var credential = result.credential;
                        var user = result.user;

                        const workosUserProfile = result.additionalUserInfo.profile
                        const displayName = workosUserProfile.first_name + ' ' + workosUserProfile.last_name


                        let userDocument = {
                            updatedOn: new Date(),
                            workosUserProfile: workosUserProfile,
                            emailVerified: true
                        }

                        // Update user doc with workosProfile
                        fb.usersCollection.doc(user.uid).update(userDocument)

                        // initialize user session
                        store.dispatch('initializeUserSession', user)

                        console.log('linked account successfully')
                        dispatch("setSSOlinkingStatus", true)
                      }).catch((error) => {
                        // Handle Errors here.
                        console.log('error',error)
                        console.log('error while linking account')
                        dispatch("sendAlert", {workflow:'onboarding', error: error})
                        dispatch("setSSOlinkingStatus", false)

                    });
                }
            })
            .catch((error) => {
              console.log(error);
              dispatch("sendAlert", {workflow:'onboarding', error: error})
              dispatch("setSSOlinkingStatus", false)
            });
        },

        async triggerSignupSlackAlert({dispatch, commit}, data){
            console.log("triggering new signup slack alert", data)
            const payload = JSON.stringify({"text": `${data.email} did signup`})
            axios({
                method: 'post',
                url: 'https://hooks.slack.com/services/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',
                headers:{
                  'Content-type': 'application/x-www-form-urlencoded'
                },
                data: payload
              })
        },

        async microsoftSignIn({ dispatch, commit }, data) {
            // Mark auth flow as in progress
            commit('SET_AUTH_IN_PROGRESS', true);

            // Get Microsoft auth provider
            var provider = new firebase.auth.OAuthProvider('microsoft.com');
            
            firebase.auth()
            .signInWithPopup(provider)
            .then((result) => {
                console.log('result',result)
                /** @type {firebase.auth.OAuthCredential} */
                var credential = result.credential;
            
                // This gives you a Google Access Token. You can use it to access the Google API.
                var token = credential.accessToken;
                // The signed-in user info.
                var user = result.user;

                // Create firestore user reference  
                var userRef = fb.usersCollection.doc(user.uid);

                userRef.get().then(async(doc) => {
                if (doc.exists) { // Check if user record already exists
                    // console.log("User exists", doc.data());

                    // Set auth mode
                    commit('SET_AUTH_MODE', 'login');

                    // initialize user session
                    store.dispatch('initializeUserSession', user)

                    // Update SignIn config
                    const data = doc.data();
                    let updatedData={
                        microsoftSignInConfig:{
                            'token':token,
                            'email_verified':user.emailVerified,
                            'photoUrl':user.photoURL,
                            'updatedOn':new Date()
                        },                        
                    }

                    // check and verify email
                    if(!data.emailVerified){
                        window.analytics.track("Email Verification via Microsoft Auth", {
                            email: user.email,
                        });
                        updatedData['emailVerified'] = true
                    }
                    // Update user record
                    fb.usersCollection.doc(user.uid).update(updatedData).then(()=>{
                        window.analytics.identify(user.email, {
                            email: user.email,
                        });
                        window.analytics.track("login", {
                            email: user.email,
                            provider: 'microsoft'
                        });
                    }).catch((error)=>{
                        dispatch("sendAlert", {workflow:'onboarding', error: error})
                    })
                    //TODO: Add a .catch here and send alert
                } else {
                    // Set auth mode
                    commit('SET_AUTH_MODE', 'signup');

                    // console.log("User doesn't exist");
                    // TODO: Move generating notetaking ID to backend (trigger_users_create)
                    const genRanHex = size => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');

                    let signupEventParams = {
                        email: user.email.toLowerCase(),
                        provider: 'microsoft'
                    }

                    let identifyCallParams = {
                        email: user.email.toLowerCase(),
                        plan: 'Teams',
                        subscription_active: false,
                        trial_active: true,
                    }

                    let userDocument = {
                        name: user.displayName,
                        email: user.email,
                        notetaking_room_id: genRanHex(10),
                        createdOn: new Date(),
                        updatedOn: new Date(),
                        microsoftSignInConfig:{
                            'token':token,
                            'email_verified':user.emailVerified,
                            'photoUrl':user.photoURL,
                            'updatedOn': new Date()
                        },
                        emailVerified: true,
                        trialEnded: false,
                        searchEnabled: true,
                        claims_last_updated_at: null
                    }
    
                    if(data.fingerprint){
                        userDocument.phUserId = `${data.fingerprint}`
                    }

                    if(data.referrer_id){
                        userDocument.referrer_id = data.referrer_id
                        signupEventParams.looppanel_referrer_id = data.referrer_id
                        identifyCallParams.looppanel_referrer_id = data.referrer_id
                        const referrer = await UserAPI.getUserEmailFromReferrerId(data.referrer_id)
                        if(referrer.status == 'success'){
                            signupEventParams.looppanel_referrer = referrer.referrer_email
                            identifyCallParams.looppanel_referrer = referrer.referrer_email
                        }
                    }

                    console.log('creating user document',{userDocument})
                    fb.usersCollection.doc(user.uid).set(userDocument).then(()=>{

                        // initialize user session
                        store.dispatch('initializeUserSession', user)

                        // Add your own unique ID here or we will automatically assign an anonymousID
                        window.analytics.identify(user.email, identifyCallParams);
                        window.analytics.track("signup",signupEventParams );

                        // TODO: Move this to the backend (trigger_users_create). If not abstract this
                        //trigger new signup slack alert
                        dispatch("triggerSignupSlackAlert", {email: user.email})

                        // Add user to the mailing list
                        store.dispatch('triggerZapierWebhook', {'user_id':user.uid,'user_profile': userDocument})

                        // Trigger ph linking 
                        if (data.fingerprint){
                            store.dispatch('link_ph_user',{'user_id':user.uid, 'fingerprint': data.fingerprint})
                        }
                    })

                }
                }).catch((error) => {
                    console.log("Error getting user document for google sign in", error);
                    dispatch("sendAlert", {workflow:'onboarding', error: error})
                });

            }).catch((error) => {
                console.log('errorMicrosoft',error)
                dispatch("sendAlert", {workflow:'onboarding', error: error})
                alert(error.message)
              // Handle Errors here.
              var errorCode = error.code;
              var errorMessage = error.message;
              // The email of the user's account used.
              var email = error.email;
              // The firebase.auth.AuthCredential type that was used.
              var credential = error.credential;
              // ...
            });
        },

        //TODO: Add alerts here
        async googleSignin({ dispatch, commit }, data) {
            try{
                // console.log('googleSignin data',data)
                // Mark auth flow as in progress
                commit('SET_AUTH_IN_PROGRESS', true);
                var provider = new firebase.auth.GoogleAuthProvider();
                var result = await firebase.auth().signInWithPopup(provider); 
                // This gives you a Google Access Token. You can use it to access the Google API.
                var token = result.credential.accessToken;
                // The signed-in user info.
                var user = result.user;
                // console.log('authenticated user result: ',result);

                var userRef = fb.usersCollection.doc(user.uid);
                const doc = await userRef.get()
                try{
                    if (doc.exists) {
                        // Retrieve the existing user data
                        const data = doc.data();

                        // Set auth mode
                        commit('SET_AUTH_MODE', 'login');
                        
                        // initialize user session
                        store.dispatch('initializeUserSession', user)

                        // Create updated data for the user document
                        let  updatedData={
                            googleSignInConfig:{
                                'token':token,
                                'email_verified':user.emailVerified,
                                'photoUrl':user.photoURL,
                                'updatedOn':new Date()
                            },                            
                        }
                        // If the user's email wasn't previously verified, update and track it
                        if(!data.emailVerified){
                            window.analytics.track("Email Verification via Google Auth", {
                                email: user.email,
                            });
                            updatedData['emailVerified'] = true
                        }

                        // Update the user's document in the database and track the login event
                        fb.usersCollection.doc(user.uid).update(updatedData).then(()=>{
                            window.analytics.identify(user.email, {
                                email: user.email,
                            });
                            window.analytics.track("login", {
                                email: user.email,
                                provider: 'google'
                            });
                        })
                    } else {
                        // Set auth mode
                        commit('SET_AUTH_MODE', 'signup');

                        // Generate notetaking id
                        const genRanHex = size => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');

                        // Parameters for the signup event
                        let signupEventParams = {
                            email: user.email.toLowerCase(),
                            provider: 'google'
                        }

                        // Parameters for the identify call,
                        let identifyCallParams = {
                            email: user.email.toLowerCase(),
                            plan: 'Teams',
                            subscription_active: false,
                            trial_active: true,
                        }

                        // User document to be stored in the database
                        let userDocument = {
                            name: user.displayName,
                            email: user.email,
                            notetaking_room_id: genRanHex(10),
                            createdOn: new Date(),
                            updatedOn: new Date(),
                            googleSignInConfig:{
                                'token':token,
                                'email_verified':user.emailVerified,
                                'photoUrl':user.photoURL,
                                'updatedOn': new Date()
                            },
                            emailVerified: true,
                            trialEnded: false,
                            searchEnabled: true,
                            claims_last_updated_at: null
                        }
                        // console.log('user doc to be created ',userDocument)

                        // Attach brewnote fingerprint
                        if(data.fingerprint){
                            userDocument.phUserId = `${data.fingerprint}`
                        }

                        // Check referrer
                        if(data.referrer_id){
                            userDocument.referrer_id = data.referrer_id
                            signupEventParams.looppanel_referrer_id = data.referrer_id
                            identifyCallParams.looppanel_referrer_id = data.referrer_id
                            const referrer = await UserAPI.getUserEmailFromReferrerId(data.referrer_id)
                            if(referrer.status == 'success'){
                                signupEventParams.looppanel_referrer = referrer.referrer_email
                                identifyCallParams.looppanel_referrer = referrer.referrer_email
                            }
                        }

                        // Write user record to the db (usersCollection)
                        const newUserDoc = await fb.usersCollection.doc(user.uid).set(userDocument)
                        // console.log('created user document ',userDocument)
                        // Add your own unique ID here or we will automatically assign an anonymousID
                        window.analytics.identify(user.email, identifyCallParams);
                        window.analytics.track("signup", signupEventParams);

                        //trigger new signup slack alert
                        dispatch("triggerSignupSlackAlert", {email: user.email})

                        // initialize user session
                        store.dispatch('initializeUserSession', user)

                        // Add user to mailing list
                        store.dispatch('triggerZapierWebhook', {'user_id':user.uid,'user_profile': userDocument})

                        // Trigger brew note migration
                        if (data.fingerprint){
                            store.dispatch('link_ph_user',{'user_id':user.uid, 'fingerprint': data.fingerprint})
                        }

                    }
                }
                catch(error){
                    console.log("Error getting user document for google sign in", error);
                    dispatch("sendAlert", {workflow:'onboarding', error: error})
                }
            
            }
            catch(error){
                console.log('errorGoogle',error)
                dispatch("sendAlert", {workflow:'onboarding', error: error})
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // The email of the user's account used.
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;
                // ...
            }
        },
        async signup({ dispatch, commit }, form) {
            // sign user up
            try {
                // Mark auth flow as in progress
                commit('SET_AUTH_IN_PROGRESS', true);

                // Sign up using email and password
                console.log("dispatch working....", form)
                // TODO: Add a try catch here with an alert in the catch block
                const { user } = await fb.auth.createUserWithEmailAndPassword(form.email, form.password)
                console.log("user created", user)
                // Set user login status
                commit('setUserLoginStatus', true)
                // Set auth mode
                commit('SET_AUTH_MODE', 'signup');

                const genRanHex = size => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');

            // create user object in userCollections
            let signupEventParams = {
                email: form.email.toLowerCase(),
                provider: 'email'
            }

            let identifyCallParams = {
                email: form.email.toLowerCase(),
                plan: 'Teams',
                subscription_active: false,
                trial_active: true,
            }

            let userDocument = {
                name: form.name,
                email: form.email.toLowerCase(),
                title: form.title,
                notetaking_room_id: genRanHex(10),
                company_name: form.companyName,
                company_id: form.companyId,
                createdOn: new Date(),
                updatedOn: new Date(),
                emailVerified: false,
                trialEnded: false,
                searchEnabled: true,
                claims_last_updated_at: null
            }

            if(form.fingerprint){
                userDocument.phUserId = `${form.fingerprint}`
            }

            if(form.referrer_id) userDocument.referrer_id = form.referrer_id

            await fb.usersCollection.doc(user.uid).set(userDocument)

            // initialize user session
            store.dispatch('initializeUserSession', user)

            if(form.referrer_id){
                identifyCallParams.looppanel_referrer_id = form.referrer_id
                signupEventParams.looppanel_referrer_id = form.referrer_id
                const referrer = await UserAPI.getUserEmailFromReferrerId(form.referrer_id)
                if(referrer.status == 'success'){
                    signupEventParams.looppanel_referrer = referrer.referrer_email
                    identifyCallParams.looppanel_referrer = referrer.referrer_email
                }
            }


            const emailVerifyContinueUrl = 'https://app.looppanel.com/verify-email'
            
            let receiverEmail  = form.email;
            let emailType = "verify_email";

            //TODO: Move this call to the backend (trigger_users_create)
            const response = await TransactionalEmailAPI.triggerTransactionalEmail(receiverEmail, emailType, emailVerifyContinueUrl);
            console.log("Verification sent to user", response)

            // Add your own unique ID here or we will automatically assign an anonymousID
            window.analytics.identify(form.email, identifyCallParams);
            window.analytics.track("signup", signupEventParams);

            //trigger new signup slack alert
            dispatch("triggerSignupSlackAlert", {email: form.email})

            // Add user to mailchimp mailing list
            store.dispatch('triggerZapierWebhook', {'user_id':user.uid,'user_profile': userDocument})

            // Trigger brewnote migration
            if (form.fingerprint){
                store.dispatch('link_ph_user',{'user_id':user.uid, 'fingerprint': form.fingerprint})
            }

            } catch (error){
                // console.log('error',error)
                // Alert user if an account already exists
                if (error.code == 'auth/email-already-in-use'){
                    alert('Account already exists. Try logging in instead.')
                    return
                }
                dispatch("sendAlert", {workflow:'onboarding', error: error})
            }
        },
        async updateProfileCompany({commit,dispatch,state},data){
            commit('setUserProfile', {...state.userProfile,...data})
        },
        async updateProfileWorkspace({commit,dispatch,state},data){
            commit('setUserProfile', {...state.userProfile,...data})
        },
        async fetchUserProfileWithoutNavigation({ commit,dispatch }, user) {
            try {
                // Set user login status
                commit('setUserLoginStatus', true)
                // fetch user profile
                // TODO: Add catch block here with an alert
                const userProfile = await fb.usersCollection.doc(user.uid).get()

                // set user profile in state
                const userProfileData = {id:user.uid,...userProfile.data()}
                //TODO: Add a check for user_email field inside the userProfileData variable
                if (userProfileData['zoom_credentials']) {
                    userProfileData['zoom_user_email'] = userProfileData['zoom_credentials']['user_email']
                    delete userProfileData['zoom_credentials']
                }
                store.dispatch("setSearchEnabled", userProfileData.searchEnabled)
                // commit("searchEnabled", userProfileData.searchEnabled)
                commit('setUserProfile', userProfileData)


            } catch(error) {
                dispatch("sendAlert", {workflow:'onboarding', error: error})
            }
        },
        /* Handles navigation for login and signup */
        async handleUserSessionNavigation({ commit,dispatch, state }, userProfileData){
            // console.log('handleUserSessionNavigation',state,userProfileData, router)

            // Get current auth mode (login or signup)
            const authMode = state.authMode

            // Get currentPath
            const currentPath = router.currentRoute.path
            
            // Check for email verification
            if ((!userProfileData.emailVerified) && (authMode === 'signup') && (currentPath != '/verify-email') && (!state.skipEmailVerification)) {
                return router.push('/verify-email').catch(error => {
                    dispatch("sendAlert", { workflow: 'onboarding', error });
                });
            }

            // Check for workspace setup
            if (!userProfileData.workspace_id && (currentPath != '/workspace')) {
                return router.push('/workspace').catch(error => {
                    dispatch("sendAlert", { workflow: 'onboarding', error });
                });
            }

            // Check for company name completion
            if ((!userProfileData.company_name) && (currentPath != '/add-company')) {
                return router.push('/add-company').catch(error => {
                    dispatch("sendAlert", { workflow: 'onboarding', error });
                });
            }

            // Goto completeSignup loading state if in signup mode
            if ((authMode === 'signup') && (currentPath != '/completeSignup')){
                commit('SET_AUTH_MODE', null);
                return router.push({ path: '/completeSignup', query: { loading: 'true' } }).catch(error => {
                    dispatch("sendAlert", { workflow: 'onboarding', error });
                });
            }

            // Redirect to home if all checks pass for login and signup auth mode
            if (currentPath != '/' && ((authMode === 'login') || (authMode === 'signup'))){
                commit('SET_AUTH_MODE', null);
                return router.push('/').catch(error => {
                    dispatch("sendAlert", { workflow: 'onboarding', error });
                });
            }
        },
        /**
         * Initializes and manages user session by fetching user, workspace, billing and transcription data,
         * updating store values, and handling router navigation for the app during
         * signup, login, or when the app is reopened.
         */
        async initializeUserSession({ commit,dispatch, state }, user) {
            // console.log('initializeUserSession',state,user)

            // Fetch user profile
            const userProfile = await fb.usersCollection.doc(user.uid).get();
            console.log(userProfile)

            // Log out and send an alert if user record doesn't exist
            if (!userProfile.exists){
                // Todo: Show an error state or message to contact support
                dispatch("sendAlert", {workflow:'onboarding', error: 'user record does not exist'})
                dispatch("logout", {});
            }
            
            // Set user login status
            commit('setUserLoginStatus', true);

            // Set user profile in state
            const userProfileData = { id: user.uid, ...userProfile.data() };

            // Check for zoom_credentials
            if (userProfileData.zoom_credentials) {
                userProfileData.zoom_user_email = userProfileData.zoom_credentials.user_email;
                delete userProfileData.zoom_credentials;
            }

            // Set searchEnabled state
            dispatch("setSearchEnabled", userProfileData.searchEnabled);
            

            // Set user profile
            commit('setUserProfile', userProfileData);

            // Fetch user workspace, billing and transcription info
            if (userProfileData.workspace_id){
                const workspaceResponse = await WorkspaceAPI.getUserWorkspace();
                if (workspaceResponse.status === 'success') {
                    const workspaceRecord = workspaceResponse.data;
                    // Set user workspace
                    dispatch("setUserWorkspace", workspaceRecord);
                    // Update billing and transcription hours
                    dispatch("fetchBillingDetails");
                    // Fetch remaining transcription hours
                    dispatch("fetchRemainingTranscriptionDuration");
                    
                    dispatch("fetchRemainingTranscriptionDuration");
                } else {
                    // user record has a workspace id but get couldn't fetch workspace. Add a retry in getUserWorkspace()
                    dispatch("sendAlert", {workflow:'onboarding', error: "user record has a workspace id but get couldn't fetch workspace"})
                    // Remove workspace_id key and force redirect to /workspace from handleUserSessionNavigation()
                    delete userProfileData.workspace_id
                }
            }
            // Enable firestore listeners
            await dispatch("setEnableFBListeners",true)

            // Handle user session navigaion
            await dispatch("handleUserSessionNavigation", userProfileData)
        },
        // Use this dispatch to only update 'emailVerified' field in the userProfile store state
        async updateUserProfileEmailVerifiedStatus({commit,dispatch},emailVerifiedStatus){
            let userProfileData = store.state.userProfile
            userProfileData ={
                ...userProfileData,
                'emailVerified' : emailVerifiedStatus
            }
            store.dispatch("updateUserProfile",userProfileData)
        },
        async updateUserProfile({ commit,dispatch }, userProfileData) {
            try {
                console.log('updating user profile',userProfileData)
                // set user profile in state
                //TODO: Add a check for user_email field inside the userProfileData variable
                if (userProfileData['zoom_credentials']) {
                    userProfileData['zoom_user_email'] = userProfileData['zoom_credentials']['user_email']
                    delete userProfileData['zoom_credentials']
                }
                store.dispatch("setSearchEnabled", userProfileData.searchEnabled)
                // commit("searchEnabled", userProfileData.searchEnabled)
                commit('setUserProfile', userProfileData)
                //Update billing info during login
                dispatch("fetchBillingDetails")
                //update remainingTranscriptionDuration info during login
                dispatch("fetchRemainingTranscriptionDuration")

            } catch(error) {
                dispatch("sendAlert", {workflow:'onboarding', error: error})
            }
        },
        async logout({ commit }, userProfile) {
            await fb.auth.signOut();
            // console.log(fb.auth.currentUser);

            window.analytics.identify(userProfile.email, {
                email: userProfile.email,
            });
            // window.analytics.group(userProfile.company_id, {
            //     name: userProfile.company_name,
            // });

            window.analytics.track('logout', {
                name: userProfile.name,
                email: userProfile.email,
            });

            // clear data and redirect to /login
            commit('setUserLoginStatus', false)
            // commit('setUserProfile', {})
            // console.log(state.userProfile)
            location.reload()
            // router.push('/login')
            window.logoutChannel.postMessage('LOGOUT')
        },
        async collab({commit},collabData){
            commit('setCollabData',collabData)
        },
        setDefaultProject({commit},defaultProject){
            commit('setDefaultProject',defaultProject)
        },
        async deleteTranscript({ state }, transcriptId) {
            // console.log("deleting session: from the store!");

            try {
                // DB_READ
                // Collection: transcriptsCollection
                // Filters: transcriptId (document ID)
                // Type: Single fetch
                // Get the zoom recording id associated with the session (zoom_recording_document_id)
                const zoomRecordingDocument = await getTranscriptDocument(transcriptId)
                const zoomRecordingDocumentId = zoomRecordingDocument.get('zoom_recording_document_id');

                // Change the status of the zoom recording document from processed to false
                await fb.zoomRecordingsCollection.doc(zoomRecordingDocumentId).set({
                    processing_status: null
                });
            } catch (error) {
                console.error('[DB_READ_ERROR] deleteTranscript():', error);
                console.log(error);
            }

            await fb.transcriptsCollection.doc(transcriptId).delete();
        },
        async deleteMedia({ state }, transcriptId) {
            // console.log("deleting transcript: from the store!");
            await fb.transcriptsCollection.doc(transcriptId).update({
                mediaUrl: null,
            });
        },
        async deleteTag({ state }, tagId) {
            // console.log("deleting tag: from the store!")
            await fb.tagsCollection.doc(tagId).delete();
        },
        async updateProfile({ dispatch }, user) {
            const userId = fb.auth.currentUser.uid
            // update user object
            await fb.usersCollection.doc(userId).update({
                name: user.name,
                title: user.title
            })

            dispatch('fetchUserProfile', { uid: userId })

        },
        async downloadZoomRecording({ state },zoomRecordingDispatchPayload) {
            // console.log('store',zoomRecordingDispatchPayload)
            // Mark as imported so that button gets disabled
            fb.zoomRecordingsCollection.doc(zoomRecordingDispatchPayload.recordingData["zoom_recording_id"]).update({
                processing_status: "processing",
            });
            const _transcriptDocument = {
                createdOn: new Date(),
                url: null,
                fileName: zoomRecordingDispatchPayload.recordingData["zoom_meeting_topic"],
                userId: fb.auth.currentUser.uid,
                userName: state.userProfile.name,
                projectId:zoomRecordingDispatchPayload.projectData.id,
                workspace_id: state.userWorkspace.id,
                tagCount:0,
                noteCount:0,
                source:'zoom_import',
                // notesProcessed:true,
                // speakerToIgnore: speakerToIgnore,
                autoTagging: true,
                // autoTagging: this.autotaggingChecked,
                // mediaUrl: ,
                processed: false,
                updatedOn: new Date(),
            }

            // Get script info for the selected project if available
            const scriptData = await QuestionScriptsAPI.getScriptByProjectId(zoomRecordingDispatchPayload.projectData.id)
            // Assign script id to the transcriptDocument
            if (scriptData != null) {_transcriptDocument.scriptId = scriptData.scriptId}
 
            // Create corresponding session/transcript object in Firestore
            fb.transcriptsCollection
                .add(_transcriptDocument)
                .then((document) => {
                    //Update session count in the project
                    // fb.projectsCollection.doc(zoomRecordingDispatchPayload.projectData.id).update({
                    //     sessionCount: firebase.firestore.FieldValue.increment(1)
                    // })

                    var payload = JSON.stringify({
                        user_id: fb.auth.currentUser.uid,
                        session_document_id: document.id,
                        recording_document_id: zoomRecordingDispatchPayload.recordingData["zoom_recording_id"],
                    });
                    // BACKEND_API
                    var config = {
                        method: "post",
                        url: getFunctionUrl('download_zoom_recording'),
                        headers: {
                            "Content-Type": "application/json",
                        },
                        data: payload,
                    };

                    axios(config)
                        .then(function (response) {
                            // JSON responses are automatically parsed.
                            console.log(response);
                            // commit("setEditorText", response.data);
                        })
                        .catch(function (e) {
                            console.log(e);
                        });
                });


        },
        async fetchBillingDetails({state}) {
            const billingResponse = await BillingAPI.getBillingDetails();
            //console.log("billing response",billingResponse)
            if(billingResponse.status === "success"){
                const billingDetails = billingResponse.data.data;
                //console.log("fetched billing details", billingDetails)
                if (billingDetails.status === "success"){
                    this.dispatch('updateBillingDetails', billingDetails.subscriptionDetails)
                }
            }else{
                console.log('error', billingResponse)
            }
        },

        async fetchRemainingTranscriptionDuration({state}){
            const response = await TranscriptionDurationAPI.getRemainingTranscriptionDuration(state.userWorkspace.id);
            if(response.status === "success"){
                const durationDetails = response.data.data;
                if (durationDetails.status === "success"){
                    this.dispatch('updateRemainingTranscriptionDuration', durationDetails.remaining_transcription_duration)
                }
            }else{
                console.log('error', response)
            }
        },
    }
})

// Add firestore listeners after user auth flow is complete
store.subscribe((mutation,state)=>{
    if (mutation.type == 'setEnableFBListeners'){
        if (state.enableFBListeners == true){        
            console.log('enabling listeners')
            let zoom_integration_status;
            let zoom_support_status;
                fb.usersCollection.doc(fb.auth.currentUser.uid)
                .onSnapshot(async (snapshot)=>{
                        // console.log("[store user listener] user docment changes..",snapshot.data())
                        const changes = snapshot.data()
                        // console.log('store snapshot: ',snapshot.data())

                        // Update auth token on the client based on key change claims_last_updated_at
                        if ((changes.claims_last_updated_at != undefined && changes.claims_last_updated_at)){
                            // console.log('claims',state.userProfile.claims_last_updated_at)
                            // console.log('[debug] Before update',new Date())
                            const id_token = await firebase.auth().currentUser.getIdToken(true)

                            // console.log('[debug] id_token',id_token)
                            // console.log('[debug] After update',new Date())
                            store.commit('SET_ID_TOKEN_STATE',true)
                        }

                        if(changes.session_expired!=undefined && changes.session_expired==true){
                            // Set session expired to false
                            console.log('[store] setting session expired to false')
                            await UserAPI.toggleSessionExpiry(fb.auth.currentUser.uid,false)
                            console.log('[store] set session expired to false')
                            store.dispatch("logout", {});
                            console.log('[store] initatied logout')
                        }

                        if(store.state.migratingWorkspace!=undefined && changes.migratingWorkspace!=undefined && store.state.migratingWorkspace != changes.migratingWorkspace){
                            if(store.state.migratingWorkspace == true && changes.migratingWorkspace==false){
                                store.dispatch('setWorkspaceMigrationNames',{from:null,to:null})
                                //Fetch the new workspace
                                const workspaceResponse = await WorkspaceAPI.getUserWorkspace()
                                if(workspaceResponse.status == 'success'){
                                    const workspace = workspaceResponse.data;
                                    store.dispatch("setUserWorkspace", workspace)

                                    //Get user's new updated role and workspace
                                    store.dispatch('fetchUserProfileWithoutNavigation', fb.auth.currentUser)
                                    // console.log('[store user listener] workspace data updated in the store',workspace)
                                    // window.location.reload()
                                }  
                                window.location.reload()
                            }
                            store.dispatch("setMigratingWorkspace",changes.migratingWorkspace)
                        }

                        //User's workspace Id has changed
                        if(store.state.userProfile && store.state.userProfile.workspace_id && store.state.userProfile.workspace_id != changes.workspace_id){
                            // console.log('[store user listener] data',store.state.userProfile.workspace_id,changes.workspace_id,typeof(store.state.userProfile.workspace_id),typeof(changes.workspace_id))
                            // console.log("[store user listener] User's workspace has changed, updating workspace data...")
                            const workspaceResponse = await WorkspaceAPI.getUserWorkspace()
                            if(workspaceResponse.status == 'success'){
                                const workspace = workspaceResponse.data;
                                const userProfileData = {id:fb.auth.currentUser.uid,...changes}

                                store.dispatch("updateUserProfile", userProfileData)
                                store.dispatch("setUserWorkspace", workspace)
                                // console.log('[store user listener] workspace data updated in the store',workspace)
                                // window.location.reload()
                            }        
                        }
                        if(store.state.searchEnabled != changes.searchEnabled){
                            store.dispatch("setSearchEnabled", changes.searchEnabled)
                            console.log('searchEnabled data updated in the store',)
                        }
                        if(store.state.userProfile && store.state.userProfile.emailVerified != changes.emailVerified){
                            store.dispatch('updateUserProfileEmailVerifiedStatus',changes.emailVerified)
                        }
                        const trialEnded = changes["trialEnded"]
                        store.commit('updateTrialEnded',trialEnded)

                        // Set zoom integration status
                        zoom_integration_status = snapshot.get('zoom_integration');
                        store.commit('setZoomIntegration', zoom_integration_status);
        
                        if (zoom_integration_status) {
        
                            // Set current recording
                            var current_recording_object = snapshot.get('active_recording')
                            current_recording_object = current_recording_object ? current_recording_object : {};
        
                        }
        
                        //fetch & update billing details incase trialEnded field changed in users doc
                        console.log("user doc changed...")
                        const billingResponse = await BillingAPI.getBillingDetails();
                        if(billingResponse.status === "success"){
                            const billingDetails = billingResponse.data.data;
                            //console.log("fetched billing details", billingDetails)
                            if (billingDetails.status === "success"){
                                store.commit('updateBillingDetails', {...billingDetails.subscriptionDetails})
                            }
                        }else{
                            console.log('error', billingResponse)
                        }
                
                
                    },
                error => {
                    store.dispatch("sendAlert", {workflow:'onboarding', error: error})
                }
                )
    } 

    }
})

store.subscribe((mutation, state) => {
    // Check if the mutation type is the one that updates `userWorkspace`
    if (mutation.type === 'setUserWorkspace') {
      // The code here will run every time `userWorkspace` is updated
      console.log('userWorkspace has been updated:', state.userWorkspace);
  
      //The listener runs everytime userWorkspace is updated
      // If userWorkspace exists and has an id, mount a listener based on userWorkspace.id
      let unsubscribe = null
      if(state.userWorkspace && state.userWorkspace.id){
        console.log('subcribing to workspace listener')
        // LISTENER
        unsubscribe = db.collection('workspaces').doc(store.state.userWorkspace.id)
        .onSnapshot(async (snapshot)=>{
                  const changes = snapshot.data()
                  console.log("workspace document changed..",changes)
  
                  const subscriptionActive = changes["subscriptionActive"]
                  const plan = changes["plan"]
                  store.commit('updateSubscriptionActive',subscriptionActive)
                  store.commit('updatePlan',plan)

                  if(changes["disableFileUploadAINotes"]){
                    const disableFileUploadAINotes = changes["disableFileUploadAINotes"]
                    store.commit('updateDisableFileUploadAINotes',disableFileUploadAINotes)
                  }

  
                  //Fetch & update transcription duration
                  const response = await TranscriptionDurationAPI.getRemainingTranscriptionDuration(store.state.userWorkspace.id);
                  if(response.status === "success"){
                      const durationDetails = response.data.data;
                      if (durationDetails.status === "success"){
                          store.commit('updateRemainingTranscriptionDuration', {...durationDetails.remaining_transcription_duration})
                      }
                  }else{
                      console.log('error', response)
                  }
  
                  //Fetch & update billing details incase subscription details fields changed in workspace doc
                  const billingResponse = await BillingAPI.getBillingDetails();
                  if(billingResponse.status === "success"){
                      const billingDetails = billingResponse.data.data;
                      //console.log("fetched billing details", billingDetails)
                      if (billingDetails.status === "success"){
                          store.commit('updateBillingDetails', {...billingDetails.subscriptionDetails})
                      }
                  }else{
                      console.log('error', billingResponse)
                  }
          },
          error => {
                console.error('[DB_READ_ERROR] workspaceCollection() Listener Error:', error);
              store.dispatch("sendAlert", {workflow:'onboarding', error: error})
          })

                console.log('here',state.userWorkspace.id)
                // DB_READ
                // Collection: botRecordingsCollection
                // Filters: workspace_id, userId
                // Type: Listener
                fb.botRecordingsCollection
                .where("workspace_id", "==", state.userWorkspace.id)
                .where("userId","==",fb.auth.currentUser.uid)
                .orderBy('meetingAt',"desc").limit(30).onSnapshot(async (snapshot)=>{            
                let changes = snapshot.docs
                console.log('inside store',changes)
                let filteredChanges=changes.filter(change=>{
                    const data=change.data();
                    if(data.currentStatus==='pre-call') return false;
                    return true;
                })
                
                filteredChanges.slice(0,1).forEach(async(change)=>{
                        let currentTime=new Date();
                            const t=true;
                        if(t){                    
                        // console.log('change from botRecordingsCollection',change.data())
                            var data = change.data();
                            let meeting_name;
                            console.log({data})
                            if(data && data.calendar_meeting_id){
                                console.log('data',data)
                                if(data.bot_type==='google-calendar'){
                                    const meeting_id=data.calendar_meeting_id;
                                    const calendarAuthToken=store.state.userProfile.calendar_config.token;
                                    const response=await CalendarAPI.retrieveMeetingInformation(calendarAuthToken,meeting_id)
                                    meeting_name=response.retrieveMeetingInformation.data.title;
                                    console.log('Response from retrieve api:',{response})
                                }
                            }else{
                                if(data && data.bot_type==='adhoc'){
                                    meeting_name='Unscheduled Call';
                                }
                            }

                            if ( data.currentStatus == 'joining_call'){
                                store.commit('setCurrentRecording', {});
                                store.commit('setSelectedScriptForNotetaking',null)
                                store.commit("setBotStatus", {
                                    id:data.bot_id, status:true , statusName:data.currentStatus, type:data.bot_type, meeting_name
                                });
                                return;
                            }
                            else if (data.currentStatus == 'in_call_recording'){
                                console.log('in_call_recording status received inside store')
                            // Figure out start timestamp for bot notetaking
                            // DB_READ
                            // Collection: transcriptsCollection
                            // Filters: transcriptId (document ID)
                            // Type: Single fetch
                            let trdata = await getTranscriptDocument(data.transcriptId)
                            console.log('data for notetaking', trdata)
                            let start_time = data.recordingStartedOn
                            let currentRecording = {
                                "script_id":trdata.scriptId,
                                "project_id":trdata.projectId,
                                "bot_id":data.bot_id,
                                'transcript_record_id':data.transcriptId,
                                "event": "recording.started",
                                "payload": {
                                "object": {
                                    "topic": trdata?.fileName  || meeting_name,
                                    "start_time": start_time,
                                    "recording_file": {
                                                "recording_start": start_time,
                                                "recording_end": ""
                                        }
                                    }
                                }
                                }
                                store.commit('setCurrentRecording', currentRecording);
                                store.commit("setBotStatus", {
                                    id:data.bot_id, status:true , statusName:data.currentStatus, type:data.bot_type, meeting_name: (trdata.fileName || meeting_name) 
                                });
                        } else if (data.currentStatus == 'done' || data.currentStatus=='call_ended'){
                                store.commit("setBotStatus", {id:null, status:false, statusName:'done', type:data.bot_type});
                                // DB_READ
                                // Collection: transcriptsCollection
                                // Filters: transcriptId (document ID)
                                // Type: Single fetch
                                let trdata = await getTranscriptDocument(data.transcriptId)
                                // console.log('data for notetaking', trdata)
                                let start_time = data.recordingStartedOn
                                let projectId = null
                                if (trdata) {
                                    projectId = trdata.projectId
                                }
                                let currentRecording = {
                                    "bot_id":data.bot_id,
                                    'transcript_record_id':data.transcriptId,
                                    "project_id": projectId,
                                    "event": "recording.stopped",
                                    "payload": {
                                    "object": {
                                        "topic": trdata?.fileName,
                                        "start_time": start_time,
                                        "recording_file": {
                                                    "recording_start": start_time,
                                                    "recording_end": ""
                                            }
                                        }
                                    }
                                    }
                                    store.commit('setCurrentRecording', currentRecording);
                                    // store.commit('setCurrentRecording', {});
                        } else if (data.currentStatus == 'in_waiting_room' || data.currentStatus == 'in_call_not_recording'){

                            store.commit("setBotStatus", {id:data.bot_id, status:true , statusName:data.currentStatus, type:data.bot_type, meeting_name});        
                        } else if (data.currentStatus == 'error' || data.currentStatus == 'fatal'){
                            store.commit('setCurrentRecording',{});
                            store.commit("setBotStatus", {id:null, status:false, statusName:'error', type:data.bot_type, meeting_name:null}); 
                        }
                    }
                })            
            }, error => {
                console.error('[DB_READ_ERROR] botRecordingsCollection Listener Error:', error);
            }) 

            // DB_READ
            // Collection: tagsCollection
            // Filters: workspace_id, userId
            // Type: Listener
            fb.tagsCollection.where("workspace_id","==", state.userWorkspace.id).where("userId", "==", fb.auth.currentUser.uid).onSnapshot(snapshot => {
                // console.log("Fetching tag records");

                let tagDataArray = []
                let tagsArray = []
                let tagNames = []

                snapshot.forEach(doc => {
                    let tag = doc.data()
                    tag.id = doc.id

                    tagDataArray.push(tag)

                    tagsArray.push({
                        text: tag.name,
                        color: tag.color,
                        style: `color: ${tag.color}; background-color:white; font-weight:700; font-size:12px;`,
                    })

                    tagNames.push({
                        id: tag.id,
                        name: tag.name,
                    })
                })

                store.commit('setTagData', tagDataArray)
                store.commit('setTags', tagsArray)
                store.commit('setTagNames', tagNames)
            }, error => {
                console.error('[DB_READ_ERROR] tagsCollection Listener Error:', error);
            })


      }else{
        //unmount workspace listener
        console.log('unsubcribing to workspace listener')
        if(unsubscribe) unsubscribe()
      }

    }
});

export default store
