<template>
  <v-app id="app">
    <!-- <div id="app"> -->
    <!-- <SiteNav v-if="showNav"></SiteNav> -->
    <TopNav v-if="showNav"></TopNav>
    <UpgradePlanModal/>
    <v-main transition="slide-x-transition" class="pa-0 mt-0">
       <!-- Keep Alive caches component and doesn't auto unmount a component -->
      <!-- Here only the root component is kept alive for persistent state across paginatio -->
      <!-- <keep-alive :include="['Dashboard']" >   -->
        <template v-if="requiresAccessControlWrapper">
            <AccessControl>
              <template v-if="requiresProjectStateWrapper">
                <ProjectState> 
                  <router-view/>
                </ProjectState>
              </template>
              <template v-else>
                <router-view/>
              </template>
            </AccessControl>
        </template>
        <template v-else>
          <router-view/>
        </template>
      <!-- </keep-alive> -->
    </v-main>
    <!-- </div> -->
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import SiteNav from "@/components/SiteNav";
import TopNav from "./components/nav/TopNav.vue";
import { auth } from './firebase';
import UpgradePlanModal from "@/components/modals/UpgradePlanModal.vue"
import AccessControl from "./hoc/AccessControl.vue";
import ProjectState from './hoc/ProjectState.vue';

export default {
  components: {
    TopNav,
    UpgradePlanModal,
    AccessControl,
    ProjectState
  },
  computed: {
    ...mapState(["userProfile"]),
    requiresAccessControlWrapper() {
      // Check if the current route requires the wrapper based on route meta
      return this.$route.meta.isCollaborative;
    },
    requiresProjectStateWrapper(){
      return (this.$route.path.includes('project') || this.$route.path.includes('editor'))
    },
    showNav() {
      // Hide nav bar on completeSignup Page
      if(this.$route.path == '/completeSignup' || this.$route.path == '/add-company' || this.$route.path == '/verify-email' || this.$route.path == '/workspace'){
        return false
      }
      // if(this.$route.path == "/workspace"){
      //   return true
      // }
      // Hide nav bar if user is not logged in
      if(!auth.currentUser){
        // console.log('not logged in')
        return false
      }
      return Object.keys(this.userProfile).length > 1;
    },
  },
};
</script>


